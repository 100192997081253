<template>
  <div>
    <!-- Header Slide Section -->
    <div
      class="header-slide mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="caption">
        <div class="box">
          <div class="container">
            <div class="inside-box py-4">
              <h1>Meet the Amchara Team</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="container my-5">
      <div class="row text-center">
        <!-- Team Colums -->
        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/content-img-1.png"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Dr Patricia Saintey</h3>
                <span>
                  <i>Doctor</i>
                </span>
                <br />
                <p>
                  <i>Malta, UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/content-img-3.png"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Gosia Chmielewska</h3>
                <span>
                  <i>Nautropath</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/Junew.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Ju Bernardes</h3>
                <span>
                  <i>Nautropath</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/whitely-300x300.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Aly Whitely</h3>
                <span>
                  <i>Holistic and Emotional Support Therapist</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/Rose.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Rose Chamberlain</h3>
                <span>
                  <i>Naturopathic Nutritionist</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/Alexandra-Falgate-e1481880095881.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Alexandra Falgate</h3>
                <span>
                  <i>Naturopath and Clinical Nutritionist</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/content-img-8.png"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Noelle Burville</h3>
                <span>
                  <i>Yoga Instructor</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/content-img-10.png"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Tracey Robinson</h3>
                <span>
                  <i>Emotional Support Therapist</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/content-img-13.png"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Laura Cooper</h3>
                <span>
                  <i>Retreat Manager</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/blank-avatar.png"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Coral Moyes</h3>
                <span>
                  <i>Receptionist</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/Anna.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Anna Middleton</h3>
                <span>
                  <i>Emotional Support</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/Bev.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Bev Alderson</h3>
                <span>
                  <i>Yoga Teacher</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/content-img-5.png"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Michael Davies</h3>
                <span>
                  <i>Chef</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/blank-avatar.png"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Julian Miu Dimana</h3>
                <span>
                  <i>Kitchen and Housekeeping</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/uk-team/Suzie.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Suzie Baum</h3>
                <span>
                  <i>Therapist</i>
                </span>
                <br />
                <p>
                  <i>UK</i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>

    <!--  -->
    <!--  -->

    <!--  -->
    <div class="container my-5">
      <div class="row text-center">
        <!-- Team Colums -->
        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/David-Haworth-NLP-Practitioner.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>David Haworth</h3>
                <span>
                  <i>NLP Practitioner</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Allison-Sheppard-Naturopath.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Allison Sheppard</h3>
                <span>
                  <i>Naturopath</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Jumuna-Haim-Naturopath.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Jumuna-Haim-Naturopath</h3>
                <span>
                  <i>Nautropath</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Linda-Rosengren-Holistic-Therapist.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Linda Rosengren</h3>
                <span>
                  <i>Holistic Therapist</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Richard-Harding-Yoga-Instructor.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Richard Harding</h3>
                <span>
                  <i>Yoga Instructor</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Ina-Kearney-Yoga-Instructor.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Ina Kearney</h3>
                <span>
                  <i>Yoga Instructor</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Wendy-Maclaughlin-Fitness-Instructor.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Wendy Maclaughlin</h3>
                <span>
                  <i>Fitness Instructor</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Katatrina-Janickova-Holistic-Therapist.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Katarina Janickova</h3>
                <span>
                  <i>Holistic Therapist</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Sally-Jobes-Colonic-Hydrotherapist.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Sally Jobes</h3>
                <span>
                  <i>Colonic Hydrotherapist</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Maria-Muscat-Colonic-Hydrotherapist.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Maria Muscat</h3>
                <span>
                  <i>Colonic Hydrotherapist</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Ron-Franken-Manager.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Ron Franken</h3>
                <span>
                  <i>Manager</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Naomi-Brazier-Assistant-Manager.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Naomi Brazier</h3>
                <span>
                  <i>Assistant Manager</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Linda-Rosengren-Receptionist.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Linda Rosengren</h3>
                <span>
                  <i>Receptionist</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Sophie-Wilson-Administration-.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Sophie Wilson</h3>
                <span>
                  <i>Administration</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Karima-Perricone-Head-Chef.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Karima Perricone</h3>
                <span>
                  <i>Head Chef</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Rodianne-Farrugia-Kitchen-Manager.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Rodianne Farrugia</h3>
                <span>
                  <i>Kitchen Manager</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Sharon-Mizzi-Kitchen-Assistant.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Sharon Mizzi</h3>
                <span>
                  <i>Kitchen Assistant</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Alexandra-Croitoru-Trainee-Chef.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Alexandra Croitoru</h3>
                <span>
                  <i>Trainee Chef</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Keith-Sancto-Head-of-Maintenance.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Keith Sancto</h3>
                <span>
                  <i>Head of Maintenance</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Dani-Vornicu-Maintenance.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Dani Vornicu</h3>
                <span>
                  <i>Maintenance</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Mihaela-Nitu-Housekeeper.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Mihaela Nitu</h3>
                <span>
                  <i>Housekeeper</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Laura-Sultana-Housekeeper.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Laura Sultana</h3>
                <span>
                  <i>Housekeeper</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Rita-Cordina-Housekeeper.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Rita Cordina</h3>
                <span>
                  <i>Housekeeper</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Jake-Lines-Brand-Executive.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Jake Lines</h3>
                <span>
                  <i>Brand Executive</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Emily-Mumford-Community-Partner-Relations.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Emily Mumford</h3>
                <span>
                  <i>Community & Partner Relations</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto mx-auto p-2">
          <div class="team-container">
            <img
              src="@/assets/images/teams/malta-team/Liz-Downer-Community-Partner-Relations.jpg"
              alt="Amchara Healthcare Team "
              style="width: 300px"
              class="team-image"
            />
            <div class="team-overlay">
              <div class="team-text">
                <h3>Liz Downer</h3>
                <span>
                  <i>Community & Partner Relations</i>
                </span>
                <br />
                <p>
                  <i>Malta</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      // bgImages: [require("@/assets/images/teams/team_header.jpg")],
    };
  },
};
</script>

<style>
</style>